<template>
  <div class="goodsc">
    <section class="save-option">
      <el-button type="primary" @click="saveGoods">
        保存
      </el-button>
    </section>
    <h3>商品图片</h3>
    <el-upload
      class="avatar-uploader"
      :action="UPLOAD_IMG_URL"
      :show-file-list="false"
      :on-success="goodsThumUploadSuccess"
      :before-upload="goodsThumUploadBefore"
    >
      <img v-if="goodsThumImg" :src="goodsThumImg" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <h3>商品名称</h3>
    <el-input
      v-model="goodsName"
      placeholder="请输入商品名称"
      class="def-short-input"
    ></el-input>

    <h3>商品视频</h3>
    <el-upload
      class="upload-video avatar-uploader"
      v-loading="videoLoading"
      :action="UPLOAD_VIDEO_URL"
      :show-file-list="false"
      :on-error="goodsVideoUploadError"
      :on-success="goodsVideoUploadSuccess"
      :before-upload="goodsVideoUploadBefore"
    >
      <video v-if="goodsVideo" controls>
        <source :src="goodsVideo" type="video/mp4" />
      </video>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <div v-if="goodsVideo" class="video-del" @click.stop="handleVideoDel">
        删除
      </div>
    </el-upload>

    <h3>商品详情图</h3>
    <el-upload
      class="avatar-uploader"
      :action="UPLOAD_IMG_URL"
      :on-remove="goodsDetailImgRemove"
      :file-list="goodsDetailImgList"
      :on-success="goodsDetailImgUploadSuccess"
      :before-upload="goodsThumUploadBefore"
      list-type="picture-card"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import {
  UPLOAD_IMG_URL,
  UPLOAD_VIDEO_URL,
  reqInsertGoods,
  reqGetGoodsInfoByID,
  reqSaveGoods,
} from '@/api'

export default {
  name: 'Goodsc',
  components: {},
  data() {
    return {
      UPLOAD_IMG_URL,
      UPLOAD_VIDEO_URL,
      videoLoading: false,
      categoryId: '',
      goodsId: '',
      goodsName: '',
      goodsThumImg: '',
      goodsVideo: '',
      goodsDetailImgList: [],
    }
  },
  mounted() {
    if (this.$route.query.edit) {
      this.getGoodsByID()
    } else {
      this.categoryId = this.$route.query.id
    }
  },
  methods: {
    getGoodsByID() {
      reqGetGoodsInfoByID(this.$route.query.id)
        .then((res) => {
          const {
            categoryId,
            goodsDetailImgs,
            goodsVideo,
            id,
            name,
            url,
          } = res.data
          this.categoryId = categoryId
          this.goodsDetailImgList = goodsDetailImgs
          this.goodsVideo = goodsVideo
          this.goodsId = id
          this.goodsName = name
          this.goodsThumImg = url
        })
        .catch((err) => {
          this.$message.success(err)
        })
        .finally(() => {})
    },
    saveGoods() {
      if (!this.goodsName) {
        this.$message.error('商品名称不能为空')
        return
      }
      if (this.$route.query.edit) {
        let upData = {
          id: this.goodsId,
          url: this.goodsThumImg,
          name: this.goodsName,
          categoryId: this.categoryId,
          goodsDetailImgs: this.goodsDetailImgList,
          goodsVideo: this.goodsVideo,
        }
        reqSaveGoods(upData)
          .then((res) => {
            this.$message.success(res.msg)
          })
          .catch((err) => {
            this.$message.success(err)
          })
          .finally(() => {})
      } else {
        let upData = {
          url: this.goodsThumImg,
          name: this.goodsName,
          categoryId: this.categoryId,
          state: 1,
          goodsDetailImgs: this.goodsDetailImgList,
          goodsVideo: this.goodsVideo,
        }
        reqInsertGoods(upData)
          .then((res) => {
            this.$message.success(res.msg)
            this.$router.go(-1)
          })
          .catch((err) => {
            this.$message.success(err)
          })
          .finally(() => {})
      }
    },
    goodsThumUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.goodsThumImg = res.data
      }
    },
    goodsThumUploadBefore(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    goodsDetailImgUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.goodsDetailImgList.push({ url: res.data })
      }
    },
    goodsDetailImgRemove(file, fileList) {
      this.goodsDetailImgList = fileList
    },
    goodsVideoUploadBefore() {
      this.videoLoading = true
    },

    goodsVideoUploadBefore(file) {
      console.log(file)
      this.videoLoading = true
    },
    goodsVideoUploadSuccess(res, file) {
      this.videoLoading = false
      if (res.code === 20051) {
        this.goodsVideo = res.data
      }
    },
    goodsVideoUploadError() {
      this.videoLoading = false
    },
    handleVideoDel() {
      this.goodsVideo = ''
    },
  },
}
</script>

<style lang="scss">
.goodsc {
  width: 100%;
  background-color: $def-content-bg-color;
  border-radius: $def-radius;
  padding: $def-margin * 4;
  position: relative;
  .video-del {
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  h3 {
    margin-top: 20px;
  }
  .save-option {
    display: flex;
    justify-content: flex-end;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  video {
    max-width: 40%;
  }

  .upload-video {
    width: 178px;
    height: 178px;
  }

  .upload-video:hover .video-del {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}
</style>
